import { isEqual, last } from 'lodash';
import * as betaSelectionHelpers from '../../helpers/selection-helpers';
import { isSingleCellSelected } from '../utils/isSingleCellSelected';

import type { Range, Cell, ColumnRow, RangeWithNoSize } from '../../types';

export const getUpdatedSelections = (
  selections: Range[] | RangeWithNoSize[],
  cells: Cell[],
): { selections: Range[]; endOfSeriesSelection?: 'offered' } => {
  const updatedSelections = selections.map((selection) => {
    const updatedSelection = betaSelectionHelpers.updateSelection(
      selection,
      selection.end,
      {
        maxCols: Infinity,
        maxRows: Infinity,
        restrictToSingleDimension: false,
      },
    );

    const {
      start: [rowStart, colStart],
      end: [rowEnd, colEnd],
    } = updatedSelection;

    if (rowStart === -1) {
      // selecting a whole row
      updatedSelection.end = [-1, colEnd];
    } else if (colStart === -1) {
      // selecting a whole column
      updatedSelection.end = [rowEnd, -1];
    }

    return {
      ...updatedSelection,
      size: betaSelectionHelpers.getSelectionSize([
        updatedSelection.start,
        updatedSelection.end,
      ]),
    };
  });

  if (!selections) {
    return {
      selections: [],
      endOfSeriesSelection: undefined,
    };
  }

  const isSingleCell = isSingleCellSelected(selections);
  const lastCell = last(selections)!.end;

  const isLastCellOfRow = isSingleCell
    ? isEqual(
        betaSelectionHelpers.getEndOfSelection(makeFullRow(lastCell), cells),
        lastCell,
      )
    : false;

  const isLastCellOfColumn = isSingleCell
    ? isEqual(
        betaSelectionHelpers.getEndOfSelection(makeFullColumn(lastCell), cells),
        lastCell,
      )
    : false;

  const currentCell = cells.find(
    ({ column, row }) => column === lastCell[0] + 1 && row === lastCell[1] + 1,
  ) || { type: '' };

  const endOfSeriesSelection =
    (isLastCellOfRow || isLastCellOfColumn) &&
    ['number', 'percentage', 'currency'].includes(currentCell.type)
      ? 'offered'
      : undefined;

  return { selections: updatedSelections, endOfSeriesSelection };
};

function makeFullRow(cell: ColumnRow) {
  return [
    [-1, cell[1]],
    [-1, cell[1]],
  ];
}

function makeFullColumn(cell: ColumnRow) {
  return [
    [cell[0], -1],
    [cell[0], -1],
  ];
}
