import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type LabelWidgetConfig = {
  messages: string[];
};

export type UtilityWidgetEditState = {
  labelWidget?: LabelWidgetConfig;
};

export const initialState: UtilityWidgetEditState = {};

export const slice = createSlice({
  name: 'utilityWidgetEdit',
  initialState,
  reducers: {
    /** Sets the text for one of the messages on a Label/Text widget */
    setMessages: (
      state: UtilityWidgetEditState,
      action: PayloadAction<string[]>,
    ) => {
      if (!state.labelWidget) {
        state.labelWidget = { messages: [] };
      }
      state.labelWidget!.messages = action.payload;
    },
    /** Add a new message to the Label/Text widget */
    addMessage(state: UtilityWidgetEditState, action: PayloadAction<string>) {
      state.labelWidget?.messages.push(action.payload);
    },
  },
});

export const actions = {
  label: {
    setMessages: slice.actions.setMessages,
    addMessage: slice.actions.addMessage,
  },
};

type RootState = {
  utilityWidgetEdit: UtilityWidgetEditState;
  [key: string]: unknown;
};

const store = (state: RootState): UtilityWidgetEditState =>
  state.utilityWidgetEdit;

export const selectors = {
  label: {
    /**
     * Grab the complete set of messages for persisting
     */
    messages: (state: RootState) => store(state).labelWidget?.messages ?? [],
  },
};

export default slice.reducer;
