import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormattedMessage, { formatMessage } from '../../../lib/i18n';
import { Row, Column } from '../../../components/layout';
import SelectDropdown from '../../../components/select-dropdown';
import Input from '../../../components/form/input';
import DurationInput from '../../../components/form/duration-input';
import Icon from '@UI/icon';
import { parseDurationInput } from '../../../lib/humanise-number';
import { every, isFinite, isArray, isUndefined, isEmpty, isNil } from 'lodash';
import classNames from 'classnames';
import { selectionToString } from '../../../spreadsheet-config-beta/helpers/selection-helpers';

import style from './status-indicators-form-style.scss';

const STATE_LABELS = {
  positive: 'universal.statusIndicators.success',
  warning: 'universal.statusIndicators.warning',
};
const REVERSE_STATES = {
  positive: 'warning',
  warning: 'positive',
};
const transformThresholdsToFormState = (
  thresholds = [],
  currentUpperState = 'warning',
) => {
  const [upper, lower = []] = thresholds.reduce(
    (memo, value) => {
      // Split our thresholds into upper and lower when we encounter
      // the 'neutral' state
      if (value === 'neutral') {
        return [[], ...memo];
      }
      memo[0].push(value);

      return memo;
    },
    [[]],
  );

  let [lowerState] = lower;
  let [, upperState] = upper;
  const [, lowerValue] = lower;
  const [upperValue] = upper;

  // Apply a default lower state when we have an upper state
  if (!lowerState && upperState) {
    lowerState = REVERSE_STATES[upperState];
  }

  // Apply a default upper state when we have a lower state
  if (!upperState && lowerState) {
    upperState = REVERSE_STATES[lowerState];
  }

  // Apply defaults when we have no values
  if (!lowerState && !upperState) {
    lowerState = REVERSE_STATES[currentUpperState];
    upperState = currentUpperState;
  }

  return {
    lowerState,
    lowerValue,
    upperState,
    upperValue,
  };
};
const transformFormStateToThresholds = ({
  lowerState,
  lowerValue,
  upperState,
  upperValue,
}) => {
  let thresholds = [];

  if (isFinite(lowerValue) || isArray(lowerValue)) {
    thresholds = [lowerState, lowerValue];
  }

  thresholds.push('neutral');

  if (isFinite(upperValue) || isArray(upperValue)) {
    thresholds = [...thresholds, upperValue, upperState];
  }

  // We have no thresholds to set so empty the array
  if (thresholds.length === 1) {
    return [];
  }

  return thresholds;
};

const renderDefaultInput = (
  format,
  name,
  currentValue,
  onChange,
  smallestInputUnit,
) => {
  const isFiniteValue = isFinite(parseFloat(currentValue));
  const isCellSelection = isArray(currentValue);

  if ('duration' === format) {
    const onDurationChange = ({ value: rawValue }) => {
      const isNullDuration = every(rawValue, (d) => d === '');
      const value = isNullDuration
        ? ''
        : parseDurationInput(rawValue, 'milliseconds');

      onChange({ value });
    };

    const displayValue =
      currentValue === '' || isNil(currentValue)
        ? undefined
        : parseFloat(currentValue);

    return (
      <Row flexWrap={false} align="center">
        <span className={style.durationInput}>
          <DurationInput
            name={name}
            value={displayValue}
            smallestInputUnit={smallestInputUnit}
            controlInputs
            onChange={onDurationChange}
          />
        </span>
        <span className={style.durationInputClear}>
          {isFiniteValue && (
            <button
              onClick={() => onChange({ value: '' })}
              className={style.iconButton}
            >
              <Icon name="times" />
            </button>
          )}
        </span>
      </Row>
    );
  }

  let value = isFiniteValue ? currentValue : '';
  let type = 'number';

  // We're going to display cell selections but disabled the input
  // we will direct the user to the edit screen where cells can be selected
  if (isCellSelection) {
    value = selectionToString(currentValue);
    type = 'text';
  }

  return (
    <Input
      isSmall
      placeholder="0"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={isCellSelection}
    />
  );
};

class StatusIndicatorsFormComponent extends PureComponent {
  constructor(props) {
    super(props);

    let upperValue, lowerValue;

    // If we already have some indicators, use it to seed the component state
    if (!isEmpty(props.indicators)) {
      const formState = transformThresholdsToFormState(
        props.indicators.thresholds,
        props.indicators._currentUpperState,
      );

      upperValue = formState.upperValue;
      lowerValue = formState.lowerValue;
    }

    this.state = { upperValue, lowerValue };
  }

  componentDidUpdate() {
    const { indicators = {}, format } = this.props;
    const { thresholds = [] } = indicators;

    if (isEmpty(indicators) || isEmpty(thresholds)) {
      // If we've updated to an empty state, clear the local component state, except for
      // durations, which are a special case
      if (format !== 'duration') {
        this.setState({ upperValue: '', lowerValue: '' });
      }

      return;
    }

    const {
      thresholds: newThresholds,
      _currentUpperState: _newCurrentUpperState,
    } = indicators;

    let { lowerValue: newLowerValue, upperValue: newUpperValue } =
      transformThresholdsToFormState(newThresholds, _newCurrentUpperState);

    const getNewValue = (rawValue) => {
      if (isUndefined(rawValue)) return '';
      if (isArray(rawValue)) return rawValue;

      return rawValue.toString();
    };
    newLowerValue = getNewValue(newLowerValue);
    newUpperValue = getNewValue(newUpperValue);

    // If we are dealing with duration values or cell selections, we don't
    // need to do anything special. Just update the value if it is different.
    if (isArray(newLowerValue) || this.props.format === 'duration') {
      if (newLowerValue !== this.state.lowerValue) {
        this.setState({ lowerValue: newLowerValue }); // eslint-disable-line
      }
    }

    if (isArray(newUpperValue) || this.props.format === 'duration') {
      if (newUpperValue !== this.state.upperValue) {
        this.setState({ upperValue: newUpperValue }); // eslint-disable-line
      }
    }

    // For numbers, if the new value is actually different to the old value (even
    // after parsing) then we want to update it. The reason for this is to prevent
    // `0.0` being parsed to `0`, meaning people can't type `0.001`, for example.
    const parsedLowerValue = parseFloat(this.state.lowerValue);
    const parsedUpperValue = parseFloat(this.state.upperValue);

    if (newLowerValue !== parsedLowerValue.toString()) {
      this.setState({ lowerValue: newLowerValue }); // eslint-disable-line
    }

    if (newUpperValue !== parsedUpperValue.toString()) {
      this.setState({ upperValue: newUpperValue }); // eslint-disable-line
    }
  }

  render() {
    const {
      onChange,
      indicators: { thresholds = [], _currentUpperState } = {},
      format,
      smallestInputUnit,
      renderInput = renderDefaultInput,
      displayThresholdTypeBelow,
    } = this.props;

    const formState = transformThresholdsToFormState(
      thresholds,
      _currentUpperState,
    );
    const { lowerState, upperState } = formState;
    const onChangeThresholds = (currentFormState) => {
      const newThresholds = transformFormStateToThresholds(currentFormState);

      onChange({
        _currentUpperState: currentFormState.upperState,
        thresholds: newThresholds,
      });
    };
    const onChangeUpperState = (newUpperState) => {
      onChangeThresholds({
        ...formState,
        lowerState: REVERSE_STATES[newUpperState],
        upperState: newUpperState,
      });
    };
    const onChangeLowerValue = ({ value }) => {
      this.setState({ lowerValue: value });

      onChangeThresholds({
        ...formState,
        lowerValue: parseFloat(value),
      });
    };
    const onChangeUpperValue = ({ value }) => {
      this.setState({ upperValue: value });

      onChangeThresholds({
        ...formState,
        upperValue: parseFloat(value),
      });
    };
    const className = classNames({
      [style.duration]: format === 'duration',
    });

    const inputContainerClassname = classNames(style.inputContainer, {
      [style.displayThresholdTypeBelowClassname]: displayThresholdTypeBelow,
    });

    return (
      <div className={className}>
        <Row className={style.statusIndicator} marginBottom={1}>
          <div className={style[upperState]} />
          <Column justify="center" marginRight={0.5} className={style.state}>
            <SelectDropdown
              selected={upperState}
              options={[
                {
                  value: 'warning',
                  label: formatMessage('universal.statusIndicators.warning'),
                },
                {
                  value: 'positive',
                  label: formatMessage('universal.statusIndicators.success'),
                },
              ]}
              onChange={onChangeUpperState}
            />
          </Column>
          <div className={inputContainerClassname}>
            <Column justify="center" marginRight={1}>
              {renderInput(
                format,
                'upper',
                this.state.upperValue,
                onChangeUpperValue,
                smallestInputUnit,
              )}
            </Column>
            <Column justify="center" className={style.thresholdType}>
              <FormattedMessage id="universal.statusIndicators.orAbove" />
            </Column>
          </div>
        </Row>
        <Row className={style.statusIndicator}>
          <div className={style[lowerState]} />
          <Column justify="center" marginRight={0.5} className={style.state}>
            <span className={style.stateLabel}>
              <FormattedMessage id={STATE_LABELS[lowerState]} />
            </span>
          </Column>
          <div className={inputContainerClassname}>
            <Column justify="center" marginRight={1}>
              {renderInput(
                format,
                'lower',
                this.state.lowerValue,
                onChangeLowerValue,
                smallestInputUnit,
              )}
            </Column>
            <Column justify="center" className={style.thresholdType}>
              <FormattedMessage id="universal.statusIndicators.orBelow" />
            </Column>
          </div>
        </Row>
      </div>
    );
  }
}

StatusIndicatorsFormComponent.defaultProps = {
  displayThresholdTypeBelow: false,
  smallestInputUnit: 'seconds',
};

StatusIndicatorsFormComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  displayThresholdTypeBelow: PropTypes.bool,
  format: PropTypes.string,
  indicators: PropTypes.shape({
    _currentUpperState: PropTypes.string,
    thresholds: PropTypes.array,
  }),
  renderInput: PropTypes.func,
  smallestInputUnit: PropTypes.string,
};

export {
  StatusIndicatorsFormComponent as default,
  transformFormStateToThresholds,
  transformThresholdsToFormState,
};
