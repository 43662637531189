import * as salesforceConfigActions from '../salesforce-config/actions/salesforce-config-actions';
import * as spreadsheetConfigActions from '../spreadsheet-config-beta/actions/spreadsheet-config-actions';
import * as spreadsheetActions from '../spreadsheet-config-beta/actions/spreadsheet-actions';
import * as datasetActions from '../dataset-config/actions/dataset-actions';
import * as datasetSaveActions from '../dataset-config/actions/dataset-save-actions';

const ACTIONS = {
  datasets: datasetActions,
  datasetsSave: datasetSaveActions,
  spreadsheets: spreadsheetActions,
  spreadsheetsSave: spreadsheetConfigActions,
  salesforce: salesforceConfigActions,
  salesforceSave: salesforceConfigActions,
};

export default (serviceName) => ACTIONS[serviceName];
