/* eslint camelcase:0 */
import {
  getSelectedCells,
  getSelectionHeaderIndices,
} from '../../spreadsheet-helpers';
import { isNil, omit, partial, zipWith, defaultTo, isEmpty } from 'lodash';
import {
  getSelectionFormat,
  getMixedInputValue,
  padXAxis,
  indexXAxis,
  indexSeries,
  alignSeries,
} from './helpers';
import { getSeries, getLabels } from './series-helpers';
import { transform as numberFormatTransform } from '../shared/number-format';
import { composeTransformers } from '../compose-transformers';

const scatterPoints = (labels, { name, data: series }) => {
  // All undefined values should be changed to null, for Tabloid to validate
  const data = zipWith(labels, series, (label, value) => [
    label,
    defaultTo(value, null),
  ]).slice(0, labels.length);

  return { name, data };
};

const lineTransform = (spreadsheet, config, timezone, initialContext = {}) => {
  const context = {};
  const {
    hasHeader,
    xAxis,
    series = [],
    goal,
    legends,
    reverseGoalDirection,
    minYAxis,
    maxYAxis,
  } = config;

  if (!xAxis) {
    return context;
  }

  const { cells } = spreadsheet;
  const seriesCells = getSelectedCells(cells, series);
  const [xAxisCells] = getSelectedCells(cells, [xAxis]);
  let format, unit, headerXAxisIndices, headerseriesKeys;

  if (xAxisCells.length) {
    // We have labels at least
    if (hasHeader) {
      const selections = [xAxis, ...series];
      const selectedCells = [xAxisCells, ...seriesCells];

      [headerXAxisIndices, ...headerseriesKeys] = getSelectionHeaderIndices(
        selections,
        selectedCells,
      );
    }
    // pad out the x axis if we're missing the cell relative to the series headers
    const paddedXAxisCells = padXAxis(xAxisCells, headerXAxisIndices);
    // Build an index from the cells we have found in our X-axis selection
    const indexedXAxis = indexXAxis(paddedXAxisCells, xAxis);

    // Build a corresponding index of our series cells
    const indexedSeries = seriesCells.map((sCells, i) => {
      return indexSeries(sCells, series[i]);
    });

    // Match our series up with our X-axis cells to create a correctly
    // aligned array of series. This ensures that any missing values are
    // filled with nulls
    const alignedSeries = alignSeries(indexedXAxis, indexedSeries);
    const seriesOfSelection = getSeries(
      alignedSeries,
      headerseriesKeys,
      legends,
    );
    const xAxisLabels = getLabels(xAxisCells, headerXAxisIndices);
    let seriesValues = seriesOfSelection.map((line) =>
      omit(line, 'type', 'currency'),
    );

    if (isEmpty(seriesValues)) {
      // If no series has been selected we would still like to render the chart for the config page
      // We can do this by passing fake values for the series down
      const name = 'Series A';
      const data = [];
      xAxisCells.forEach(() => data.push(0));
      seriesValues = hasHeader ? [{ name, data }] : [{ data }];
    } else {
      // We also need to ensure that the data array within each series object does not contain only null values
      // This occurence can happen if a user selects a Series then clears the input
      seriesValues = seriesValues.map((seriesObj) => {
        const { name } = seriesObj;
        let { data } = seriesObj;

        // Here we are checking that if every element in the array is null, if so we pass down fake values for the series
        if (data.every((element) => isNil(element))) {
          data = [];
          xAxisCells.forEach(() => data.push(0));
        }

        return { name, data };
      });
    }

    switch (xAxisLabels.type) {
      case 'number':
        // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
        const labels = xAxisLabels.labels.map(parseFloat);
        context.series = seriesValues.map(partial(scatterPoints, labels));
        context.x_axis = {};
        break;

      case 'datetime':
        context.series = seriesValues.map(
          partial(scatterPoints, xAxisLabels.labels),
        );
        context.x_axis = { type: 'datetime' };
        break;

      default:
        context.series = seriesValues;
        context.x_axis = xAxisLabels;
    }

    [format, unit] = getSelectionFormat(seriesOfSelection);
    context.y_axis = { format, unit };
  }

  context.threshold = getMixedInputValue(cells, goal, format);
  context.reverseGoalDirection = reverseGoalDirection;
  context.minYAxis = getMixedInputValue(cells, minYAxis, format);
  context.maxYAxis = getMixedInputValue(cells, maxYAxis, format);

  return { ...initialContext, ...context };
};

const transform = composeTransformers(lineTransform, numberFormatTransform);

export { transform };
