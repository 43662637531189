import { includes, isUndefined, startsWith } from 'lodash';
import * as customizeActions from '../actions/customize-actions';
import {
  UPLOAD_LOGO_STATE_STARTED,
  UPLOAD_LOGO_STATE_SUCCEEDED,
  UPLOAD_LOGO_STATE_FAILED,
  UPLOAD_CUSTOM_CSS_STATE_STARTED,
  UPLOAD_CUSTOM_CSS_STATE_SUCCEEDED,
  UPLOAD_CUSTOM_CSS_STATE_FAILED,
} from '../customize-constants';
import { getBackgroundColor } from '../../dashboard/themes/utilities';
import {
  USER_THEME,
  DARK_THEME,
  AUTO_WIDGET_STYLE,
} from '../../dashboard/themes/theme-constants';
import { toHexCode } from '../../lib/hex-code';
import { FRAME_WIDTH } from '../../dashboard/box-layout/box-layout-constants';
import { applyRatioToWidgetLayout } from '../../dashboard/box-layout/helpers';

const initialState = {
  originalColumnsValue: undefined,
  isLoading: true,
  isSaving: false,
  uploadLogoState: null,
  logoError: null,
  uploadCustomCSSState: null,
  customCSSError: null,
  themeBackgroundColorInput: undefined,
  showGridPreview: false,
  values: undefined,
};

const customizeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case customizeActions.loading.type: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case customizeActions.initialiseValues.type: {
      const {
        theme,
        scale_when_fullscreen: scaleToFit,
        tv_mode: tvMode,
        toggles,
        css,
        customization = {},
        columns,
      } = payload.dashboard;

      const {
        showFooter = true,
        showClock = true,
        showLogo = true,
        logoSrc = null,
        themeBackgroundColor = getBackgroundColor(DARK_THEME),
        themeWidgetStyle = AUTO_WIDGET_STYLE,
      } = customization;

      const largeTitles = includes(toggles, 'large_widget_titles');

      const values = {
        theme,
        themeBackgroundColor,
        themeWidgetStyle,
        scaleToFit,
        tvMode,
        showFooter,
        showClock,
        showLogo,
        logoSrc,
        cssFile: css,
        columns,
        gridSize: FRAME_WIDTH / columns,
        widgets: {
          largeTitles,
        },
      };

      return {
        ...state,
        originalColumnsValue: columns,
        themeBackgroundColorInput: themeBackgroundColor,
        isLoading: false,
        values,
        widgets: payload.widgets,
      };
    }

    case customizeActions.cancel.type: {
      return initialState;
    }

    case customizeActions.setTheme.type: {
      const { theme, themeBackgroundColor } = state.values;
      let newBackground = themeBackgroundColor;

      if (payload === USER_THEME && isUndefined(themeBackgroundColor)) {
        newBackground = getBackgroundColor(theme);
      }

      return {
        ...state,
        themeBackgroundColorInput: newBackground,
        customCSSLoadedInPage: false,
        values: {
          ...state.values,
          theme: payload,
          themeBackgroundColor: newBackground,
        },
      };
    }

    case customizeActions.setThemeBackground.type: {
      const color = startsWith(payload, '#') ? payload : `#${payload}`;
      const {
        values: { themeBackgroundColor },
      } = state;
      const newThemeBackgroundColor = toHexCode(color) || themeBackgroundColor;

      return {
        ...state,
        themeBackgroundColorInput: color,
        values: {
          ...state.values,
          themeBackgroundColor: newThemeBackgroundColor,
        },
      };
    }

    case customizeActions.setThemeWidgetStyle.type:
      return {
        ...state,
        values: {
          ...state.values,
          themeWidgetStyle: payload,
        },
      };

    case customizeActions.setShowGridPreview.type:
      return {
        ...state,
        showGridPreview: payload,
      };

    case customizeActions.setScaleToFit.type:
      return {
        ...state,
        values: {
          ...state.values,
          scaleToFit: payload,
        },
      };

    case customizeActions.setTvMode.type:
      return {
        ...state,
        values: {
          ...state.values,
          tvMode: payload,
        },
      };

    case customizeActions.setLargeTitles.type:
      return {
        ...state,
        values: {
          ...state.values,
          widgets: {
            ...state.values.widgets,
            largeTitles: payload,
          },
        },
      };

    case customizeActions.setShowFooter.type:
      return {
        ...state,
        values: {
          ...state.values,
          showFooter: payload,
        },
      };

    case customizeActions.setShowClock.type:
      return {
        ...state,
        values: {
          ...state.values,
          showClock: payload,
        },
      };

    case customizeActions.setShowLogo.type:
      return {
        ...state,
        values: {
          ...state.values,
          showLogo: payload,
        },
      };

    case customizeActions.setLogoSuccessful.type:
      return {
        ...state,
        logoError: null,
        values: {
          ...state.values,
          logoSrc: payload,
        },
      };

    case customizeActions.setLogoValidationFailed.type:
    case customizeActions.setLogoFailed.type:
      return {
        ...state,
        logoError: payload,
        values: {
          ...state.values,
          logoSrc: null,
        },
      };

    case customizeActions.saveStart.type:
      return {
        ...state,
        isSaving: true,
      };

    case customizeActions.saveSuccessful.type:
      return {
        ...state,
        values: undefined,
        widgets: undefined,
        isSaving: false,
      };

    case customizeActions.saveFailed.type:
      return {
        ...state,
        isSaving: false,
      };

    case customizeActions.uploadLogoStart.type:
      return {
        ...state,
        uploadLogoState: UPLOAD_LOGO_STATE_STARTED,
      };

    case customizeActions.uploadLogoSuccessful.type:
      return {
        ...state,
        uploadLogoState: UPLOAD_LOGO_STATE_SUCCEEDED,
        values: {
          ...state.values,
          logoSrc: payload,
        },
      };

    case customizeActions.uploadLogoFailed.type:
      return {
        ...state,
        uploadLogoState: UPLOAD_LOGO_STATE_FAILED,
        logoError: 'customize.logoUploadFailed',
        values: {
          ...state.values,
          logoSrc: null,
        },
      };

    case customizeActions.setCustomCSSValidationFailed.type: {
      return {
        ...state,
        customCSSError: payload,
        values: {
          ...state.values,
          cssFile: null,
        },
      };
    }

    case customizeActions.uploadCustomCSSStart.type: {
      return {
        ...state,
        customCSSError: null,
        uploadCustomCSSState: UPLOAD_CUSTOM_CSS_STATE_STARTED,
        customCSSLoadedInPage: false,
      };
    }

    case customizeActions.uploadCustomCSSSuccessful.type: {
      return {
        ...state,
        uploadCustomCSSState: UPLOAD_CUSTOM_CSS_STATE_SUCCEEDED,
        values: {
          ...state.values,
          cssFile: payload,
        },
      };
    }

    case customizeActions.uploadCustomCSSFailed.type: {
      return {
        ...state,
        uploadCustomCSSState: UPLOAD_CUSTOM_CSS_STATE_FAILED,
        customCSSError: 'customize.css.uploadFailed',
        values: {
          ...state.values,
          cssFile: null,
        },
      };
    }

    case customizeActions.setCustomCSSLoaded.type: {
      return {
        ...state,
        customCSSLoadedInPage: true,
      };
    }

    case customizeActions.setGridSize.type: {
      const ratio = payload / state.values.gridSize;

      const newWidgets = Object.keys(state.widgets).reduce((acc, widgetKey) => {
        const widget = state.widgets[widgetKey];

        acc[widgetKey] = {
          ...widget,
          layout: applyRatioToWidgetLayout(widget.layout, ratio),
        };

        return acc;
      }, {});

      return {
        ...state,
        widgets: newWidgets,
        values: {
          ...state.values,
          gridSize: payload,
        },
      };
    }

    case customizeActions.setColumns.type: {
      return {
        ...state,
        values: {
          ...state.values,
          columns: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default customizeReducer;
export { initialState };
