import {
  instrumentGoalAdded,
  instrumentGoalEdited,
  instrumentGoalRemoved,
} from '@Tracking/events';
import { trackEvent } from '@Tracking/index';
import { isEqual, isUndefined } from 'lodash';
import { ConfigComparison } from '@Lib/graphql-legacy-config-mappers';

type TrackingDetails = {
  'Instrument ID': string;
  Visualisation: string;
  'Integration name'?: string;
  'Integration slug'?: string;
  'Data source'?: string;
};

type OriginalGoal = {
  targetValue?: string | number | Array<number>;
  startingValue?: string | number | Array<number>;
};

type Config = {
  goal?: number | Array<number> | string;
  comparison?: ConfigComparison;
  type: string;
};

export const trackGoal = async (
  trackingDetails: TrackingDetails,
  originalGoal?: OriginalGoal,
  config?: Config,
) => {
  if (config && !['number', 'bar', 'column', 'line'].includes(config.type)) {
    return;
  }

  const targetValue = originalGoal?.targetValue;
  const startingValue = originalGoal?.startingValue;

  const newGoal = config?.goal;
  const newComparison = config?.comparison;

  const hasGoalValuesChanged =
    !isEqual(targetValue, newGoal) ||
    !isEqual(startingValue, newComparison?.startingValue);

  const hasOriginalGoalValues =
    !isUndefined(startingValue) || !isUndefined(targetValue);

  let hasGoalRemoved;

  // when removing spreadsheets number goal value, the value stays in the store
  // so we need to check if there's a goal type in comparison for number vizType
  // to figure out whether the number goal has been removed
  // no matter what platform is it has a goal type as long as it has a goal value.
  if (config?.type === 'number') {
    hasGoalRemoved = hasOriginalGoalValues && isUndefined(newComparison?.type);
  } else {
    hasGoalRemoved = hasOriginalGoalValues && isUndefined(newGoal);
  }

  if (hasGoalValuesChanged) {
    if (!hasOriginalGoalValues) {
      await trackEvent(instrumentGoalAdded(trackingDetails));
      return;
    }

    if (hasGoalRemoved) {
      await trackEvent(instrumentGoalRemoved(trackingDetails));
      return;
    }

    await trackEvent(instrumentGoalEdited(trackingDetails));
  }
};
