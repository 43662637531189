import { isArray } from 'lodash';

import type { ColumnRow } from '../../../spreadsheet-config-beta/types';
import { getColumnLetter } from '../../../spreadsheet-config-beta/helpers/selection-helpers';

export const fromNumberOrCell = (
  value: undefined | [ColumnRow, ColumnRow] | number,
): string | undefined => {
  if (value === undefined) {
    return undefined;
  }

  if (typeof value === 'number' && !isNaN(value)) {
    return value.toString();
  }

  // An array must be spreadsheet cell reference
  if (isArray(value)) {
    const [, end] = value;

    // We only allow single cell selection, so just use the `end` value
    let [col, row] = end;

    // If a whole row/column is selected, pick the first cell instead
    if (col === -1) col = 0;
    if (row === -1) row = 0;

    return getColumnLetter(col) + String(row + 1);
  }

  return undefined;
};
