export const PLATFORMS = {
  HEIRLOOM: 'heirloom',
  BAUHAUS: 'bauhaus',
  UNIVERSAL: 'universal',
  DATASETS: 'datasets',
  DATABASE: 'database',
  SALESFORCE: 'salesforce',
  SPREADSHEETS: 'spreadsheets',
  INTEGRATION_DATASETS: 'integrationDatasets',
} as const;

export const STATUS = {
  ALPHA: 'alpha',
  BETA: 'beta',
  LEGACY: 'legacy',
  NEW: 'new',
} as const;

export const AUTH_TYPES = {
  NONE: 'null',
  BASIC: 'basic',
  OAUTH: 'oauth',
  OAUTH2: 'oauth2',
} as const;

type GetConnectPath = (params: {
  dashboardId: string;
  dashboardIsOwnedByRoadie?: boolean;
}) => string;

export type Integration = {
  name: string;
  slug: string;
  authType: (typeof AUTH_TYPES)[keyof typeof AUTH_TYPES];
  keywords: string[];
  platform: (typeof PLATFORMS)[keyof typeof PLATFORMS];
  status?: (typeof STATUS)[keyof typeof STATUS];
  isPro?: boolean;
  getConnectPath: GetConnectPath;
};

type IntegrationSetup = Omit<Integration, 'getConnectPath'> & {
  getConnectPath?: GetConnectPath;
};

/**
 * The integrations list
 *
 * This list is just the definitions of our integrations. Once
 * initialised each integration in this list has the following
 * properties.
 *
 * {string} name - The human readable integration name
 * {string} slug - The ID (not always unique)
 * {string} authType - The auth mechanism when creating connections
 * {string} platform - The internal platform that the integration runs on
 * {string?} status - A status to mark the integration as alpha/beta/legacy/new
 * {boolean?} isPro - A boolean to mark whether the integration is pro
 * {string[]} keywords - Words to match when searching for an integration
 * {function} getConnectPath - A function that returns the relative path to the connection panel
 */
const integrationDefinitions: IntegrationSetup[] = [
  {
    name: 'ActiveCampaign',
    slug: 'activecampaign',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'AirBrake',
    slug: 'airbrake',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Aircall',
    slug: 'aircall2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Airtable',
    slug: 'airtable',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Amazon Cloudwatch',
    slug: 'cloudwatch',
    authType: AUTH_TYPES.BASIC,
    keywords: ['aws', 'ec2', 'amazon web service'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Amazon Connect',
    slug: 'amazonconnect',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
    status: STATUS.ALPHA,
  },
  {
    name: 'Amazon Seller Central',
    slug: 'amazonsellercentral',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
    status: STATUS.ALPHA,
  },
  {
    name: 'Amplitude',
    slug: 'amplitude',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'data.ai',
    slug: 'appannie',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['itunes', 'app store', 'play', 'app figures', 'appannie'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'AppFigures',
    slug: 'appfigures2',
    authType: AUTH_TYPES.OAUTH,
    keywords: ['itunes', 'app store', 'play'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Asana',
    slug: 'asana',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Bannerbear',
    slug: 'bannerbear',
    authType: AUTH_TYPES.BASIC,
    keywords: ['bannerbear', 'image'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Baremetrics',
    slug: 'baremetrics',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['stripe', 'braintree', 'recurly', 'chargebee'],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Basecamp 2',
    slug: 'basecampnew',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Buffer',
    slug: 'buffer',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Campaign Monitor',
    slug: 'campaignmonitor2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['campaignmonitor'],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Chargify',
    slug: 'chargify',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Chartbeat',
    slug: 'chartbeat',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'ChartMogul',
    slug: 'chartmogul',
    authType: AUTH_TYPES.BASIC,
    keywords: [
      'stripe',
      'braintree',
      'recurly',
      'chargify',
      'paypal',
      'cleverbridge',
      'chargeover',
      'chargebee',
      'gocardless',
    ],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'ClickUp',
    slug: 'clickup',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Close',
    slug: 'close',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Copper',
    slug: 'coppercrm',
    authType: AUTH_TYPES.BASIC,
    keywords: ['copper', 'crm'],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Databases',
    slug: 'database',
    authType: AUTH_TYPES.NONE,
    keywords: [
      'amazon',
      'aws',
      'azure',
      'bigquery',
      'database',
      'db',
      'heroku',
      'maria',
      'mariadb',
      'mongodb',
      'mssql',
      'mysql',
      'oracle',
      'postgresql',
      'redshift',
      'snowflake',
      'sql',
      'sqlite',
      'sqlserver',
    ],
    platform: PLATFORMS.DATABASE,
    isPro: true,
  },
  {
    name: 'Datasets API',
    slug: 'datasets',
    authType: AUTH_TYPES.NONE,
    keywords: [
      'integration',
      'http',
      'api',
      'push',
      'json',
      'sql',
      'number',
      'bar',
      'datasets',
      'custom',
    ],
    platform: PLATFORMS.DATASETS,
  },
  {
    name: 'Delighted',
    slug: 'delighted2',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Dixa',
    slug: 'dixa',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Dotdigital',
    slug: 'dotdigital',
    authType: AUTH_TYPES.BASIC,
    keywords: ['marketing'],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Excel',
    slug: 'hostedexcel',
    authType: AUTH_TYPES.NONE,
    keywords: ['spreadsheet', 'excel', 'office', 'worksheet', 'microsoft'],
    platform: PLATFORMS.SPREADSHEETS,
    getConnectPath: ({ dashboardId, dashboardIsOwnedByRoadie }) =>
      getSpreadsheetsConnectPath(dashboardId, !!dashboardIsOwnedByRoadie),
  },
  {
    name: 'Facebook',
    slug: 'facebook5',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Facebook Ads',
    slug: 'facebookads2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Flurry',
    slug: 'flurry',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'FreshBooks',
    slug: 'freshbooks2',
    authType: AUTH_TYPES.OAUTH,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Freshdesk',
    slug: 'freshdesk',
    authType: AUTH_TYPES.BASIC,
    keywords: ['freshdesk'],
    platform: PLATFORMS.UNIVERSAL,
  },
  {
    name: 'Freshservice',
    slug: 'freshservice',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'GitHub',
    slug: 'github3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Google Ads',
    slug: 'googleads',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['adwords'],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Google AdSense',
    slug: 'adsense',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Google Analytics 4',
    slug: 'ga4',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['ga', 'adsense'],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Google Calendar',
    slug: 'google_calendar2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Google Search Console',
    slug: 'google_search_console',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Google Sheets',
    slug: 'gsheets',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['spreadsheet', 'gdocs'],
    platform: PLATFORMS.SPREADSHEETS,
    getConnectPath: ({ dashboardId, dashboardIsOwnedByRoadie }) =>
      getSpreadsheetsConnectPath(dashboardId, !!dashboardIsOwnedByRoadie),
  },
  {
    name: 'Gorgias',
    slug: 'gorgias',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'GoSquared',
    slug: 'gosquared',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Help Scout',
    slug: 'helpscout',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Heroku Dataclips',
    slug: 'herokudataclip',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Highrise',
    slug: 'highrise',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'HubSpot',
    slug: 'hubspot3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.UNIVERSAL,
    isPro: true,
  },
  {
    name: 'HubSpot',
    slug: 'hubspot4',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
    isPro: true,
    status: STATUS.BETA,
  },
  {
    name: 'Instagram Business',
    slug: 'instagram4',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Intercom',
    slug: 'intercom4',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['intercom'],
    platform: PLATFORMS.BAUHAUS,
    isPro: true,
  },
  {
    name: 'Jenkins',
    slug: 'jenkins',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Jira Cloud',
    slug: 'jiracloud',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Klaus',
    slug: 'klaus',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Klaviyo',
    slug: 'klaviyo',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Linear',
    slug: 'linear',
    authType: AUTH_TYPES.BASIC,
    keywords: ['linear.app'],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'LinkedIn',
    slug: 'linkedin3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'LinkedIn Ads',
    slug: 'linkedinads',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Mailchimp',
    slug: 'mailchimp3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Mention',
    slug: 'mention',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Mixpanel',
    slug: 'mixpanel2',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'monday.com',
    slug: 'monday',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Matomo Cloud',
    slug: 'matomo',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'New Relic',
    slug: 'newrelic2',
    authType: AUTH_TYPES.BASIC,
    keywords: ['newrelic'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Nicereply',
    slug: 'nicereply',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Pager Duty',
    slug: 'pagerduty2',
    authType: AUTH_TYPES.BASIC,
    keywords: ['pagerduty'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Paypal',
    slug: 'paypal',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Pingdom',
    slug: 'pingdom',
    authType: AUTH_TYPES.BASIC,
    keywords: ['pingdom', 'solarwinds'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Pipedrive',
    slug: 'pipedrive3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Pivotal Tracker',
    slug: 'pivotal',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'ProfitWell',
    slug: 'profitwell',
    authType: AUTH_TYPES.BASIC,
    keywords: ['stripe', 'braintree', 'zuora', 'chargebee', 'recurly'],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'QuickBooks Online',
    slug: 'quickbooks',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Recurly',
    slug: 'recurly',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Redash',
    slug: 'redash',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'RingCentral',
    slug: 'ringcentral',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'RSS/Atom Feed',
    slug: 'rss/atom_feed',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    getConnectPath: ({ dashboardId, dashboardIsOwnedByRoadie }) => {
      // This is a really old integration and its the only
      // one that, rather annoyingly, has a forward slash
      // in its slug.

      // To avoid any URL based strangeness we need to
      // encode this slug when we put it in the URL.
      const encodedSlug = encodeURIComponent('rss/atom_feed');

      if (dashboardIsOwnedByRoadie) {
        return `/v5/dashboards/${dashboardId}/connect/${PLATFORMS.HEIRLOOM}/${encodedSlug}`;
      }

      return `/v4/dashboards/${dashboardId}/connect/${PLATFORMS.HEIRLOOM}/${encodedSlug}`;
    },
  },
  {
    name: 'Salesforce',
    slug: 'salesforce',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.SALESFORCE,
    isPro: true,
  },
  {
    name: 'SendGrid',
    slug: 'sendgrid2',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'ShipStation',
    slug: 'shipstation',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Shopify',
    slug: 'shopify2',
    authType: AUTH_TYPES.BASIC,
    keywords: ['shopify', 'ecommerce'],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Space Cargo',
    slug: 'spacecargo',
    authType: AUTH_TYPES.BASIC,
    keywords: ['spacecargo'],
    platform: PLATFORMS.UNIVERSAL,
  },
  {
    name: 'Space Cargo Heirloom',
    slug: 'spacecargoheirloom',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Space Cargo Bauhaus',
    slug: 'spacecargobauhaus',
    authType: AUTH_TYPES.BASIC,
    keywords: ['spacecargo'],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Spreadsheets',
    slug: 'spreadsheets',
    authType: AUTH_TYPES.NONE,
    keywords: ['spreadsheet', 'worksheet', 'onedrive', 'dropbox', 'sharepoint'],
    platform: PLATFORMS.SPREADSHEETS,
    getConnectPath: ({ dashboardId, dashboardIsOwnedByRoadie }) =>
      getSpreadsheetsConnectPath(dashboardId, !!dashboardIsOwnedByRoadie),
  },
  {
    name: 'StatusCake',
    slug: 'statuscake',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Stripe Subscriptions',
    slug: 'stripe2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'TeamCity',
    slug: 'teamcity',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'TikTok',
    slug: 'tiktok',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'Trello',
    slug: 'trello2',
    authType: AUTH_TYPES.OAUTH,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Tumblr',
    slug: 'tumblr',
    authType: AUTH_TYPES.OAUTH,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Twilio',
    slug: 'twilio',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Vimeo',
    slug: 'vimeo',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Wistia',
    slug: 'wistia',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
  },
  {
    name: 'YouTube Analytics',
    slug: 'youtube5',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
  },
  {
    name: 'Zendesk Agent Status',
    slug: 'zendeskagentstatus',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
    isPro: true,
  },
  {
    name: 'Zendesk Chat',
    slug: 'zendeskchat',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.UNIVERSAL,
    isPro: true,
  },
  {
    name: 'Zendesk Sell',
    slug: 'zendesksell',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
    isPro: true,
  },
  {
    name: 'Zendesk Support',
    slug: 'zendesk3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.BAUHAUS,
    isPro: true,
  },
  {
    name: 'Zendesk Talk',
    slug: 'zendesktalk',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    isPro: true,
  },

  /**
   * UTILITY INTEGRATIONS
   */
  {
    name: 'Clock',
    slug: 'clock',
    authType: AUTH_TYPES.NONE,
    keywords: ['time', 'date', 'day'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Image',
    slug: 'image',
    authType: AUTH_TYPES.NONE,
    keywords: ['photo', 'picture', 'gif', 'jpeg', 'jpg', 'png'],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'QR Code',
    slug: 'qrcode',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
  },
  {
    name: 'Text',
    slug: 'text',
    authType: AUTH_TYPES.NONE,
    keywords: ['heading', 'title'],
    platform: PLATFORMS.HEIRLOOM,
  },

  /**
   * LEGACY INTEGRATIONS
   */
  {
    name: 'Aircall',
    slug: 'aircall',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
    status: STATUS.LEGACY,
  },
  {
    name: 'Campaign Monitor',
    slug: 'campaignmonitor',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'CSV',
    slug: 'csv',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Custom Widgets API',
    slug: 'custom',
    authType: AUTH_TYPES.NONE,
    keywords: [
      'integration',
      'http',
      'api',
      'push',
      'json',
      'xml',
      'map',
      'sql',
      'number',
      'pie',
      'highcharts',
      'bar',
    ],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
    getConnectPath: ({ dashboardId, dashboardIsOwnedByRoadie }) => {
      if (dashboardIsOwnedByRoadie) {
        return `/v5/dashboards/${dashboardId}/connect/custom`;
      }
      return `/v4/dashboards/${dashboardId}/connect/heirloom/custom`;
    },
  },
  {
    name: 'Delighted',
    slug: 'delighted',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Facebook',
    slug: 'facebook4',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Google Ads',
    slug: 'adwords3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Google Analytics',
    slug: 'ga2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['ga', 'adsense'],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Universal Google Analytics',
    slug: 'ga2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['ga', 'adsense'],
    platform: PLATFORMS.UNIVERSAL,
    status: STATUS.LEGACY,
  },
  {
    name: 'Instagram Business',
    slug: 'instagram3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Intercom',
    slug: 'intercom3',
    authType: AUTH_TYPES.OAUTH2,
    keywords: ['intercom'],
    platform: PLATFORMS.UNIVERSAL,
    status: STATUS.LEGACY,
  },
  {
    name: 'LinkedIn & LinkedIn Ads',
    slug: 'linkedin2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Mixpanel',
    slug: 'mixpanel',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'New Relic',
    slug: 'newrelic',
    authType: AUTH_TYPES.BASIC,
    keywords: ['newrelic'],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Pipedrive',
    slug: 'pipedrive2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.UNIVERSAL,
    status: STATUS.LEGACY,
  },
  {
    name: 'Salesforce',
    slug: 'salesforce',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'SatisMeter',
    slug: 'satismeter',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.INTEGRATION_DATASETS,
    status: STATUS.LEGACY,
  },
  {
    name: 'Shopify',
    slug: 'shopify',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'StatHat',
    slug: 'stathat',
    authType: AUTH_TYPES.BASIC,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Stripe',
    slug: 'stripe2',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'Trello',
    slug: 'trello',
    authType: AUTH_TYPES.OAUTH,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
  {
    name: 'YouTube Analytics',
    slug: 'youtube4',
    authType: AUTH_TYPES.OAUTH2,
    keywords: [],
    platform: PLATFORMS.HEIRLOOM,
    status: STATUS.LEGACY,
  },
];

const defaultGetConnectPath =
  (integration: IntegrationSetup): GetConnectPath =>
  ({ dashboardId, dashboardIsOwnedByRoadie }) => {
    const version = dashboardIsOwnedByRoadie ? 'v5' : 'v4';
    return `/${version}/dashboards/${dashboardId}/connect/${integration.platform}/${integration.slug}`;
  };

const getSpreadsheetsConnectPath = (
  dashboardId: string,
  dashboardIsOwnedByRoadie: boolean,
) => {
  const version = dashboardIsOwnedByRoadie ? 'v5' : 'v4';
  return `/${version}/dashboards/${dashboardId}/connect/spreadsheets`;
};

const createIntegration = (integration: IntegrationSetup): Integration => ({
  getConnectPath: defaultGetConnectPath(integration),
  ...integration,
});

const integrations = integrationDefinitions.map(createIntegration);

export const getIntegration = (
  slug: string,
  platform?: (typeof PLATFORMS)[keyof typeof PLATFORMS],
): Integration => {
  if (!platform) {
    return integrations.find((i) => i.slug === slug)!;
  }

  return integrations.find((i) => i.slug === slug && i.platform === platform)!;
};

export const isProIntegration = (slug: string) => {
  const integration = getIntegration(slug);

  if (!integration) return false;

  return Boolean(integration.isPro);
};

export default integrations;
