import { graphql } from '@Generated/gql';
import apolloClient from './concierge-service/apollo-client';

export async function uploadLogo(file: File): Promise<string> {
  const client = apolloClient.create();
  const result = await client.mutate({
    mutation: LOGO_PARAMS_MUTATION,
    variables: { contentType: file.type },
  });
  if (!result.data?.logoUploadParametersGenerate) {
    throw new Error('bad response');
  }
  const { url, parameters } = result.data.logoUploadParametersGenerate;

  const formData = new FormData();
  parameters.forEach(({ key, value }) => {
    formData.append(key, value);
  });
  formData.append('file', file);

  const postResponse = await postLogo(url, formData);
  const location = postResponse.headers.get('location');
  if (!location) {
    throw new Error('failed upload');
  }
  return decodeURIComponent(location);
}

export const LOGO_PARAMS_MUTATION = graphql(`
  mutation LogoParams($contentType: String!) {
    logoUploadParametersGenerate(contentType: $contentType) {
      url
      parameters {
        key
        value
      }
    }
  }
`);

function postLogo(url: string, body: FormData) {
  // Expect external URL like S3 here. No need to apply csrfFetch
  return fetch(url, {
    method: 'POST',
    body,
    headers: {
      enctype: 'multipart/form-data',
    },
  }).then((response) => {
    if (response.status === 204) {
      return response;
    }
    throw new Error('Logo upload failed');
  });
}
