import * as serviceLib from './service';
import { includes } from 'lodash';
import { getDashboardFromStorage } from '../dashboard/graphql-dashboard-helpers';
import { PLATFORM_NAMES } from '../data-sources/data-sources-helpers';

const getServicePath = (serviceName, options, dashboardIsOwnedByRoadie) => {
  const { dashboardId, platform } = options;

  const encodedServiceName = encodeURIComponent(serviceName);
  const version = dashboardIsOwnedByRoadie ? 'v5' : 'v4';

  if (platform) {
    return `/${version}/dashboards/${dashboardId}/connect/${platform}/${encodedServiceName}`;
  }

  const isSpreadsheets = includes(
    ['spreadsheets', 'excel', 'googlesheets'],
    serviceName,
  );
  if (isSpreadsheets) {
    return `/${version}/dashboards/${dashboardId}/connect/spreadsheets`;
  }

  if (dashboardIsOwnedByRoadie && serviceName === 'custom') {
    // On RODs we no longer treat custom widgets as part of the heirloom platform,
    // so in this case we have to return a different url for custom.
    return `/v5/dashboards/${dashboardId}/connect/custom`;
  }

  // We are currently not passing the platform through for our heirloom integrations
  // So if no platform is provided we can rest assured it's our heirloom integration
  return `/${version}/dashboards/${dashboardId}/connect/heirloom/${encodedServiceName}`;
};

const getNewHeirloomInstrumentConfigPath = (
  serviceName,
  { dashboardId, widgetTypeId, widgetTypeKey, dashboardIsOwnedByRoadie },
  hasV6LabelWidgetConfig = false,
) => {
  const encodedServiceName = encodeURIComponent(serviceName);
  const isUtilityWidget = ['clock', 'image', 'text'].includes(serviceName);
  const isTextWidget = serviceName === 'text';

  // Bypass the edit screen for text widgets
  if (hasV6LabelWidgetConfig && isTextWidget) {
    return `/edit/dashboards/${dashboardId}/utility/create`;
  }

  // Utility widgets were previously configured as a kind of faux heirloom widget.
  // The preset panel opening when you click on a utility pill on the integrations list
  // is an artefact of this decision. On RODs, the config forms for utility widgets
  // are bespoke, and completely separate from heirloom.
  //
  // We should really remove the preset panel for these utilities, as it serves no purpose.
  // In the meantime, we need to ensure the customer ends up on the bespoke config pages
  // when creating utility widgets on RODs, rather than the heirloom config form.
  //
  // Note that one difference between utility widgets and other platforms is that the utilities
  // do not (currently) have their platform (`utilities`) in the URL, so this URL is different to
  // the current convention.
  if (dashboardIsOwnedByRoadie && isUtilityWidget) {
    return `/v5/dashboards/${dashboardId}/new/${encodedServiceName}`;
  }

  if (dashboardIsOwnedByRoadie) {
    return `/v5/dashboards/${dashboardId}/new/heirloom/${encodedServiceName}/${widgetTypeKey}`;
  }

  // Send the customer to the config form that's powered by gecko-js
  return `/v4/dashboards/${dashboardId}/widgets/new?integration=${encodedServiceName}&widgetTypeId=${widgetTypeId}`;
};

const getEditWidgetPath = ({
  dashboardId,
  widgetKey,
  serviceName,
  platform,
  dashboardIsOwnedByRoadie,
  instrumentId,
  shouldOpenSIPanel,
}) => {
  if (dashboardIsOwnedByRoadie) {
    // TODO: When we support more integrations for RODs, we'll have
    // to return more information in the URL (similar to v4 URLs below)

    if (serviceName === 'spreadsheets') {
      let configurePath = `/v5/dashboards/${dashboardId}/edit/${instrumentId}/configure`;
      if (shouldOpenSIPanel) {
        configurePath += '?opensi=true';

        return configurePath;
      }
      return configurePath;
    }
    return `/v5/dashboards/${dashboardId}/edit/${instrumentId}`;
  }

  let path = `/v4/dashboards/${dashboardId}`;

  if (!widgetKey) {
    return path;
  }

  path += `/widgets/${widgetKey}`;

  if (serviceLib.isUniversal(serviceName)) {
    return `${path}/universal`;
  }

  if (platform && platform === 'INTEGRATION_DATASETS') {
    return `${path}/${PLATFORM_NAMES.INTEGRATION_DATASETS}`;
  }

  switch (serviceName) {
    case 'pegasus':
      path += '/salesforce';
      break;
    case 'spreadsheets':
      path += '/spreadsheetsbeta/configure';

      if (shouldOpenSIPanel) {
        path += '?opensi=true';
      }
      break;
    case 'datasets':
      path += '/datasets';
      break;
    default:
      break;
  }

  return path;
};

// Return dashboard path with GraphQL id if it's set.
export const getDashboardPath = (dashboardId, widgetId, integrationSlug) => {
  let isContainerLayout = false;
  let dashboard;
  // getDashboardFromStorage only supports getting the dashboard using its
  // legacyId, so if we pass a dash_ ID to this function, just assume
  // we're on a containerLayout, and probably a ROD.
  if (typeof dashboardId === 'string' && dashboardId.startsWith('dash_')) {
    isContainerLayout = true;
  } else {
    // Get all the dashboards from the session storage. See if the dashboard layout
    // is a container layout. If yes, redirect to the correct dashboard path
    // using the graphql dashboard id.
    dashboard = getDashboardFromStorage(dashboardId) || {};
    const { layoutType = {} } = dashboard;
    isContainerLayout = layoutType.__typename === 'ContainerLayout';
  }

  const lastIntegrationUsed = !!integrationSlug
    ? `?lastIntegrationUsed=${integrationSlug}`
    : '';

  if (isContainerLayout) {
    return `/edit/dashboards/${
      dashboard?.id || dashboardId
    }${lastIntegrationUsed}`;
  }

  const widgetHash = !!widgetId ? `#${widgetId}` : '';
  return `/edit/dashboards/${dashboardId}${widgetHash}${lastIntegrationUsed}`;
};

const getUrlParams = () => new URLSearchParams(window.location.search);

export {
  getNewHeirloomInstrumentConfigPath,
  getServicePath,
  getEditWidgetPath,
  getUrlParams,
};
